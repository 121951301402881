<template>
    <div class="row">
        <template v-if="anyAppConnected">
            <div class="col-md-6">
                <app-posts
                    id="bw-upcoming-col"
                    :title="$t('dashboard.upcoming.title')"
                    query-type="upcoming"
                >
                </app-posts>
            </div>
            <div class="col-md-6">
                <app-posts
                    id="bw-top-col"
                    :title="$t('dashboard.top.title')"
                    query-type="top"
                >
                </app-posts>
            </div>
        </template>

        <div
            v-else
            class="col-md-12"
        >
            <div class="no-connections">
                <div class="no-connections__icon">
                    <a
                        class="reset-link-none"
                        :href="connectionPage"
                    >
                        <i
                            class="fa fa-4x fa-plus"
                            aria-hidden="true"
                        ></i>
                    </a>
                </div>
                <div class="text-center w-75 m-auto">
                    <a
                        class="reset-link"
                        :href="connectionPage"
                    >
                        <h1 class="h5 mb-3">{{ $t("dashboard.noConnection.title") }}</h1>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AppPosts from "@/components/dashboard/AppPosts.vue";
    import { connectionConfigValues } from "@/components/apps-connection-config.js";
    import { views } from "@/components/common/views-config.js";

    const profile = window.profileData;

    export default {
        name: "AdvisorDashboard",
        components: {
            AppPosts,
        },
        data: function () {
            return {
                connectionPage: views.profile_connections(profile.slug),
            };
        },
        computed: {
            anyAppConnected() {
                // TODO: use store to get enabled apps. Currently store is too slow to be used here.
                return (
                    Object.keys(connectionConfigValues).filter(
                        (app_slug) => profile.applications[app_slug].is_enabled
                    ).length > 0
                );
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "src/assets/scss/_variables.scss";
    .no-connections {
        display: flex;
        flex-direction: column;

        &__icon {
            align-items: center;
            align-self: center;
            background: $cloud;
            border: 2px solid $greyish;
            border-radius: 50%;
            color: $greyish-500;
            display: flex;
            height: 110px;
            justify-content: center;
            margin-bottom: 2rem;
            width: 110px;
        }
    }
</style>
